$(document).ready(() => {
	// products parsing
	$(".def-block__slider--product .woocommerce .product").each(function () {
		const slider = $(this)
			.closest(".def-block__slider--product")
			.find(".swiper-wrapper");
		$(this).addClass("swiper-slide").appendTo(slider);
	});

	// Menu
	$(".header__buttonNav").on("click", () => {
		$(".header")
			.toggleClass("active-nav")
			.removeClass("active-search")
			.removeClass("active-catalog")
			.removeClass("active-cart");
		$("body").toggleClass("no-scrolling");
	});

	// Search
	$(".js-search").on("click", () => {
		$(".header")
			.toggleClass("active-search")
			.removeClass("active-catalog")
			.removeClass("active-cart")
			.addClass("js-anim");
	});

	// Catalog Header
	$(".header__buttonCatalog").on("click", () => {
		$(".header")
			.toggleClass("active-catalog")
			.removeClass("active-search")
			.removeClass("active-cart")
			.addClass("js-anim");
	});

	$(".header__substrate").on("click", () => {
		$(".header")
			.removeClass("active-search")
			.removeClass("active-catalog")
			.removeClass("active-cart")
			.addClass("js-anim");
	});

	// Animation for Header
	const headerScroll = () => {
		let lastScrollTop = 0;
		const header = $(".header");
		const toUp = $(".toUp");

		$(window).scroll(function () {
			let nowScrollTop = $(this).scrollTop();

			if (nowScrollTop > lastScrollTop && nowScrollTop > 80) {
				header.addClass("js-fixed").removeClass("js-anim");
				toUp.addClass("visible");
			} else if (nowScrollTop === 0) {
				header.removeClass("js-fixed").removeClass("js-anim");
				toUp.removeClass("visible");
			} else if (nowScrollTop < lastScrollTop) {
				header.addClass("js-anim");
			}

			lastScrollTop = nowScrollTop;
		});
	};

	headerScroll();

	// To Up
	$(".toUp").on("click", function () {
		$("body, html").animate({ scrollTop: 0 }, 1000);
	});

	if (
		$(".widget_recently_viewed_products").closest(".container").length === 0
	) {
		$(".widget_recently_viewed_products")
			.wrap('<div class="overflow"/>')
			.wrap('<div class="container"/>');
	}

	// slider control
	const sliderControl = () =>
		`
			<div class="controlSwiper">
				<div class="controlSwiper__link controlSwiper__link--prev">
					<img src="${window.location.origin}/wp-content/themes/furniturein/img/arrow.svg" alt="">
				</div>
				<div class="controlSwiper__link controlSwiper__link--next">
					<img src="${window.location.origin}/wp-content/themes/furniturein/img/arrow.svg" alt="">
				</div>
			</div>
		`;

	const sliderCustomControl = () =>
		`
			<div class="controlSwiperCustom">
				<div class="controlSwiperCustom__link controlSwiperCustom__link--prev">
					<img src="${window.location.origin}/wp-content/themes/furniturein/img/arrow-grey.svg" alt="">
				</div>
				<div class="controlSwiperCustom__link controlSwiperCustom__link--next">
					<img src="${window.location.origin}/wp-content/themes/furniturein/img/arrow-grey.svg" alt="">
				</div>
			</div>
		`;

	const galleryProduct = () => {
		$(".woocommerce-product-gallery__wrapper").addClass("swiper-container");
		$(".woocommerce-product-gallery__image")
			.addClass("swiper-slide")
			.wrapAll('<div class="swiper-wrapper"/>');

		const mainSlider = $(".woocommerce-product-gallery__wrapper")
			.clone()
			.addClass("woocommerce-product-gallery__main")
			.removeClass("woocommerce-product-gallery__wrapper");

		$(".woocommerce-product-gallery").append(mainSlider);
		$(".woocommerce-product-gallery__main").wrap(
			'<div class="product-gallery__wrap" />'
		);
		$(".woocommerce-product-gallery__wrapper").wrap(
			'<div class="product-gallery__dopWrap" />'
		);

		$(".product-gallery__dopWrap").append(() => sliderCustomControl());

		$(".product-gallery__wrap .woocommerce-product-gallery__main").append(() =>
			sliderControl()
		);

		// All origin img
		$(".woocommerce-product-gallery__main img").each(function () {
			const originURL = $(this).attr("data-src");

			$(this).attr("src", originURL);
			$(this).removeAttr("sizes");
		});

		const sliderMainGallery = new Swiper(".woocommerce-product-gallery__main", {
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: ".product-gallery__wrap .controlSwiper__link--next",
				prevEl: ".product-gallery__wrap .controlSwiper__link--prev",
			},
		});

		const sliderGallery = new Swiper(".woocommerce-product-gallery__wrapper", {
			slidesPerView: 5,
			spaceBetween: 10,
			direction: "vertical",
			autoHeight: true,
			navigation: {
				nextEl: ".woocommerce-product-gallery .controlSwiperCustom__link--next",
				prevEl: ".woocommerce-product-gallery .controlSwiperCustom__link--prev",
			},
			breakpoints: {
				599: {
					slidesPerView: 4,
					direction: "horizontal",
				},
			},
		});

		sliderMainGallery.on("slideChange", function () {
			const i = sliderMainGallery.realIndex;

			sliderGallery.slideTo(i);
		});

		$("body").on(
			"click",
			".woocommerce-product-gallery__wrapper .woocommerce-product-gallery__image",
			function () {
				const i = $(this).index();

				sliderMainGallery.slideTo(i);
			}
		);

		if (
			$(
				".woocommerce-product-gallery__wrapper .swiper-slide:last-child"
			).index() +
				1 ===
			1
		) {
			$(".woocommerce-product-gallery__wrapper").remove();
		}

		if (
			$(
				".woocommerce-product-gallery__wrapper .swiper-slide:last-child"
			).index() +
				1 <=
			5
		) {
			$(".controlSwiperCustom").addClass("hide");
		}

		$("body").on("click", ".product-gallery__wrap", function (e) {
			const control = $(".product-gallery__wrap .controlSwiper");
			if (!control.is(e.target) && control.has(e.target).length === 0) {
				const gall = $(".product-gallery__wrap");
				const wrap = $(".woocommerce-product-gallery");
				const h = wrap.outerHeight();

				gall.addClass("opacity");
				setTimeout(() => {
					gall.toggleClass("active").removeClass("opacity");
					gall.hasClass("active")
						? wrap.css("height", h + "px")
						: wrap.css("height", "auto");
					sliderMainGallery.update();
				}, 300);
			}
		});
	};

	$("body").on(
		"click",
		".woocommerce-product-gallery__main a, .woocommerce-product-gallery__wrapper a",
		(e) => {
			e.preventDefault();
		}
	);

	galleryProduct();

	$(".product_title.entry-title").after($(".product_meta"));

	// recently viewed
	const recentlyViewed = () => {
		$(".widget_recently_viewed_products .product_list_widget").addClass(
			"swiper-container js-products"
		);
		$(
			".widget_recently_viewed_products .product_list_widget .swiper-slide"
		).wrapAll('<div class="swiper-wrapper"/>');
		$(".widget_recently_viewed_products .widget-title").append(() =>
			sliderControl()
		);

		const sliderWiewed = new Swiper(".product_list_widget", {
			slidesPerView: 5,
			spaceBetween: 0,
			navigation: {
				nextEl: ".widget_recently_viewed_products .controlSwiper__link--next",
				prevEl: ".widget_recently_viewed_products .controlSwiper__link--prev",
			},
			breakpoints: {
				1600: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 2,
				},
				479: {
					slidesPerView: 1,
				},
			},
		});
	};

	recentlyViewed();

	// related product custom
	const relatedProduct = () => {
		$(".related.products").removeClass("products");
		$(".related .products").addClass("swiper-container js-products");
		$(".related .product").addClass("swiper-slide");
		$(".related .product").wrapAll('<div class="swiper-wrapper"/>');
		$(".related > h2").append(() => sliderControl());
		$(".related")
			.wrap('<div class="overflow related-wrap"/>')
			.wrap('<div class="container"/>');
		$(".content-wrap").after($(".related-wrap"));

		const sliderRelated = new Swiper(".related .products", {
			slidesPerView: 5,
			spaceBetween: 0,
			navigation: {
				nextEl: ".related .controlSwiper__link--next",
				prevEl: ".related .controlSwiper__link--prev",
			},
			breakpoints: {
				1600: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 2,
				},
				479: {
					slidesPerView: 1,
				},
			},
		});
	};

	relatedProduct();

	// up-sells
	const upSells = () => {
		$(".up-sells.products").removeClass("products");
		$(".up-sells .products").addClass("swiper-container js-products");
		$(".up-sells .product").addClass("swiper-slide");
		$(".up-sells .product").wrapAll('<div class="swiper-wrapper"/>');
		$(".up-sells > h2").append(() => sliderControl());
		$(".up-sells")
			.wrap('<div class="overflow up-sells-wrap"/>')
			.wrap('<div class="container"/>');
		$(".content-wrap").after($(".up-sells-wrap"));

		const sliderUpSells = new Swiper(".up-sells .products", {
			slidesPerView: 5,
			spaceBetween: 0,
			navigation: {
				nextEl: ".up-sells .controlSwiper__link--next",
				prevEl: ".up-sells .controlSwiper__link--prev",
			},
			breakpoints: {
				1600: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 2,
				},
				479: {
					slidesPerView: 1,
				},
			},
		});
	};

	upSells();

	// Check cookie name
	function getCookie(name) {
		const matches = document.cookie.match(
			new RegExp(
				"(?:^|; )" +
					name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
					"=([^;]*)"
			)
		);

		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	// Cookies
	function cookies() {
		const cookies = $(".cookies");
		const data = getCookie("consent");

		if (!data) {
			cookies.addClass("js-show");

			$(".cookies .btn").on("click", function (e) {
				e.preventDefault();

				cookies.removeClass("js-show");
				document.cookie = "consent=true";
			});
		}
	}

	cookies();

	// Clamping Footer
	const clampingFooter = () => {
		const footerWidth = $(".footer").outerHeight();

		$(".height-helper").css("margin-top", `-${footerWidth}px`);
		$(".content-wrap").css("padding-top", `${footerWidth}px`);
	};

	// Events Resize elements
	$(window).on("resize", () => {
		clampingFooter();
		reviewOverflow();
	});

	// Review overflow
	const reviewOverflow = () => {
		const wrap = $(".js-overflow-review");
		const oneLine = 19;
		const blockHeight = wrap.closest(".reviews__text").height();
		const newBlockHeight = blockHeight / oneLine;

		(newBlockHeight ^ 0) === newBlockHeight
			? wrap.height(`${newBlockHeight * oneLine}px`)
			: wrap.height(`${Math.floor(newBlockHeight) * oneLine}px`);
	};

	reviewOverflow();

	// PopUp
	function popUp() {
		$(".js-popup-button").on("click", function (e) {
			e.preventDefault();
			const popupClass = `.${$(this).attr("data-popupShow")}`;

			$(".popup").removeClass("js-popup-show");
			$(popupClass).addClass("js-popup-show");
			$("body").addClass("no-scrolling");
		});

		// Close PopUp
		$(".js-close-popup").on("click", function (e) {
			e.preventDefault();

			$(".popup").removeClass("js-popup-show");
			$("body").removeClass("no-scrolling");
		});

		$(".popup").on("click", function (e) {
			const wrap = $(".popup__wrap");
			if (
				!wrap.is(e.target) &&
				wrap.has(e.target).length === 0 &&
				!$(this).hasClass("swiperBig")
			) {
				$(".popup").removeClass("js-popup-show");
				$("body").removeClass("no-scrolling");
			}

			if ($(this).hasClass("swiperBig")) {
				const wrapBig = $(".popup__product-wrap");
				const wrapArr = $(".popup__product-control");

				if (
					!wrapBig.is(e.target) &&
					wrapBig.has(e.target).length === 0 &&
					!wrapArr.is(e.target) &&
					wrapArr.has(e.target).length === 0
				) {
					$(".popup").removeClass("js-popup-show");
					$("body").removeClass("no-scrolling");
				}
			}
		});
	}

	popUp();

	// Select
	const pageCheckout = $("[data-checkout]");
	if (!pageCheckout.length) {
		$("select:not(.account select):not(.custom-select-var)").selectric({
			maxHeight: 200,
			disableOnMobile: false,
			nativeOnMobile: false,
		});

		$("select:not(.account select)").selectric({
			maxHeight: 200,
			disableOnMobile: false,
			nativeOnMobile: false,
		});
	}

	$(".account select").select2();

	// $('select').each(function() {
	// 	const $p = $(this).parent();
	// 	$(this).select2({
	// 		dropdownParent: $p
	// 	});
	// });

	// Slider head Section
	if ($("div").hasClass("head__slider")) {
		const headSliderLength =
			$(".head__slider .swiper-slide:last-child").index() + 1;

		$("#js-sum-slide").text(
			headSliderLength <= 9 ? "0" + headSliderLength : headSliderLength
		);

		const headSlider = new Swiper(".head__slider", {
			slidesPerView: 1,
			navigation: {
				nextEl: ".head__sliderNav--next",
				prevEl: ".head__sliderNav--prev",
			},
			on: {
				slideChange: () => {
					const index = headSlider.realIndex + 1;
					$("#js-current-slide").text(index <= 9 ? "0" + index : index);
				},
			},
		});
	}

	if ($("div").hasClass("def-block__slider")) {
		$(".def-block__slider").each(function () {
			const parent = $(this).closest(".def-block");
			const nextArrow = parent.find(".def-block__controlLink--next");
			const prevArrow = parent.find(".def-block__controlLink--prev");

			// View
			const view = parseInt($(this).attr("data-view"));
			const view1366 = parseInt($(this).attr("data-view-1366"));
			const view1199 = parseInt($(this).attr("data-view-1199"));
			const view991 = parseInt($(this).attr("data-view-991"));
			const view768 = parseInt($(this).attr("data-view-768"));

			const sliderBlock = new Swiper($(this), {
				slidesPerView: view || 5,
				spaceBetween: 0,
				navigation: {
					nextEl: nextArrow,
					prevEl: prevArrow,
				},
				breakpoints: {
					1366: {
						slidesPerView: view1366 || 4,
					},
					1199: {
						slidesPerView: view1199 || 3,
					},
					991: {
						slidesPerView: view991 || 3,
					},
					768: {
						slidesPerView: view768 || 2,
					},
					479: {
						slidesPerView: 1,
					},
				},
			});
		});
	}

	// Footer accordion
	$(".footer__nav .menu > .menu-item > a").on("click", function (e) {
		e.preventDefault();

		const parent = $(this).closest(".menu-item");
		const isActive = parent.hasClass("active");

		$(".footer__nav .menu > .menu-item").removeClass("active");

		isActive ? parent.removeClass("active") : parent.addClass("active");
	});

	// Product Hover
	// $('.product').hover(function () {
	// 	setTimeout(() => {
	// 		$(this).addClass('pointer-visible');
	// 	}, 200);
	// }, function () {
	// 	setTimeout(() => {
	// 		$(this).removeClass('pointer-visible');
	// 	}, 200);
	// });

	$("body").on("mouseenter", ".product", function () {
		setTimeout(() => {
			$(this).addClass("pointer-visible");
		}, 300);
	});

	$("body").on("mouseleave", ".product", function () {
		setTimeout(() => {
			$(this).removeClass("pointer-visible");
		}, 300);
	});

	// random
	const randomInteger = (min, max) => {
		let rand = min + Math.random() * (max + 1 - min);
		rand = Math.floor(rand);

		return rand;
	};

	// add product form
	const subFormProduct = () => {
		const subForm = $(".js-productForm");
		const l = $(".shop__content .products .product").length;

		// Config
		const min = 2;
		const max = 6;

		if (l <= 6) {
			$(".shop__content .products .product")
				.eq(l - 1)
				.before(subForm);
		} else {
			$(".shop__content .products .product")
				.eq(randomInteger(min, max))
				.before(subForm);
		}
	};

	// Woocommerce shop Page
	if ($(".shop").length === 1) {
		subFormProduct();
		$(".shop__content .products").after($(".woocommerce-pagination"));
	}

	// Redirect sort shop
	const sortProducts = $(".shop .orderby option[selected]").val();

	$(".shop .orderby").on("change", function () {
		const val = $(this).val();
		const localURL = location.href;
		const originURL = location.origin;

		const pathname = location.pathname;

		if (localURL.indexOf("orderby") !== -1) {
			location.href = localURL.replace(`${sortProducts}`, `${val}`);
		} else {
			location.href = `${originURL}${pathname}?orderby=${val}`;
		}
	});

	// Update Cart
	const updateCart = () => {
		$('button[name="update_cart"]').removeAttr("disabled");
	};

	// quantity input
	const quantity = () => {
		$("body").on("click", ".quantity__sub", function () {
			const input = $(this).closest(".quantity").find("input");

			if (input.val() > parseInt(input.attr("min"))) {
				input.val((i, oldval) => --oldval);
			}

			updateCart();
			$('button[name="update_cart"]').trigger("click");
		});

		$("body").on("click", ".quantity__add", function () {
			const input = $(this).closest(".quantity").find("input");

			input.val((i, oldval) => ++oldval);

			updateCart();
			$('button[name="update_cart"]').trigger("click");
		});
	};

	quantity();

	// Single product
	$(".summary").prepend($(".shop--product .container > .product > .onsale"));
	$("#tab-description").addClass("visual-editor");

	$("#site-header-cart > li:first-child").append(
		() =>
			`
		<div class="header__basketClose">
			<img src="${window.location.origin}/wp-content/themes/furniturein/img/close-w.svg" alt="">
		</div>
		`
	);

	// Header cart
	$(".header__basketView").on("click", () => {
		$(".header")
			.addClass("active-cart")
			.removeClass("active-search")
			.removeClass("active-catalog")
			.addClass("js-anim");
	});

	$("body").on("click", ".header__basketClose", () =>
		$(".header").removeClass("active-cart")
	);

	// Update count basket
	$(".header__basketView").text($(".site-header-cart .count").text());

	// Tabs
	$(".woocommerce-tabs").before($(".shop__tabs"));
	$(".woocommerce-tabs").wrap('<div class="shop__tabs-content" />');
	$(".woocommerce-tabs").after($(".shop__reviews"));

	$(".shop__tabs li").on("click", function () {
		if ($(this).hasClass("active")) return;

		$(".shop__tabs li").removeClass("active");
		$(this).addClass("active");

		const i = $(this).index();

		$(".shop__tabs-content > *").slideUp(300);
		$(".shop__tabs-content > *").eq(i).slideDown(300);
	});

	// Msg single product
	if ($(".shop--product .woocommerce-message").length !== 0) {
		$(".shop--product .woocommerce-message a").remove();
		const msgText = $(".shop--product .woocommerce-message").text();

		$(".shop__msg").text(msgText);
		$(".popup-msg").addClass("js-popup-show");
	}

	// Cart
	const cartViewed = () => {
		$(".cart-section .cross-sells .products").addClass(
			"swiper-container js-products"
		);
		$(".cart-section .cross-sells .products .product").addClass("swiper-slide");
		$(".cart-section .cross-sells .products .product").wrapAll(
			'<div class="swiper-wrapper"/>'
		);
		$(".cart-section .cross-sells > h2").append(() => sliderControl());

		const sliderCart = new Swiper(".cart-section .cross-sells .products", {
			slidesPerView: 5,
			spaceBetween: 0,
			navigation: {
				nextEl: ".cart-section .cross-sells .controlSwiper__link--next",
				prevEl: ".cart-section .cross-sells .controlSwiper__link--prev",
			},
			breakpoints: {
				1600: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 2,
				},
				479: {
					slidesPerView: 1,
				},
			},
		});
	};

	cartViewed();

	// Checkout
	$(
		"#customer_details .col-1 .form-row, .authentication .form-row, .woocommerce-address-fields .form-row, .saph_field, .shipping_address .form-row, .woocommerce-form-login.login .form-row"
	).each(function () {
		const input = $(this).find("input");
		const label = $(this).find("label");

		if (label.hasClass("checkbox")) return;

		input.attr("placeholder", label.text());
		label.remove();
	});

	document.addEventListener("wpcf7mailsent", function ({ path, detail }) {
		if ($(path[1]).hasClass("product__subWrap")) {
			$(".js-seccess-msg")
				.animate({ opacity: 0 }, 500, function () {
					$(this).text(detail.apiResponse.message);
				})
				.animate({ opacity: 1 }, 500);
		}
	});

	// Authentication
	$(".js-login, .js-register").on("click", function (e) {
		e.preventDefault();

		$(".authentication__login").toggleClass("active");
		$(".authentication__register").toggleClass("active");
	});

	if (
		$(".authentication").length !== 0 &&
		$(".woocommerce-notices-wrapper").children().length !== 0
	) {
		const msgText = $(".woocommerce-notices-wrapper").html();

		// $('.authentication__login.active .custom-error').html(msgText);
		// $('.authentication__register.active .custom-error').html(msgText);

		$(".custom-error").html(msgText);

		$(".woocommerce-notices-wrapper").remove();
	}

	if ($(".authentication--thank").length !== 0) {
		$(".woocommerce-message").appendTo(".custom-error");
	}

	if ($(".account").length !== 0) {
		$(".woocommerce-notices-wrapper").appendTo(".custom-error");
	}

	// All Error
	if ($(".cart-section").length !== 0) {
		$(".woocommerce-notices-wrapper").appendTo(".custom-error");
	}

	if ($(".checkout-section").length !== 0) {
		$('.checkout .btn[type="submit"]').on("click", function () {
			$(".woocommerce-NoticeGroup").remove();

			const watch = setInterval(() => {
				if ($(".woocommerce-NoticeGroup").length !== 0) {
					$(".checkout .woocommerce-NoticeGroup").appendTo(".custom-error");
					clearInterval(watch);
				}
			}, 50);
		});
	}

	$(".order-thank__print a").on("click", (e) => {
		e.preventDefault();

		window.print();
	});

	// personal Area menu
	$(".woocommerce-MyAccount-navigation").on("click", function () {
		$(this).toggleClass("active");
	});

	$(document).on("click", function (e) {
		const wrap = $(".woocommerce-MyAccount-navigation");
		if (!wrap.is(e.target) && wrap.has(e.target).length === 0) {
			wrap.removeClass("active");
		}
	});

	const buttonSearch = (white) => {
		let color = white ? "search-w" : "search";

		return `
				<button type="submit" class="header__search-submit">
					<img src="/wp-content/themes/furniturein/img/${color}.svg" alt="">
				</button>
			`;
	};

	$(buttonSearch()).prependTo("#product-search-form-1");
	$(buttonSearch(true)).prependTo("#product-search-form-0");
	$(".product-search-form form").addClass("header__search-form");
	$(".product-search-field").addClass("header__search-input");

	// Title search
	const editTitleSearch = () => {
		const el = $(".woocommerce-products-header__title");
		const text = el.text();

		if (text.indexOf("Результат поиска:") !== -1) {
			el.html(text.replace(":", ": <br>"));
		}
	};

	editTitleSearch();

	// Compare
	if ($(".woocommerce-products-compare-content").length !== 0) {
		$(".woocommerce-products-compare-content")
			.wrap('<section class="compare-section def-padding"/>')
			.wrap('<div class="container"/>');
		$(".compare-section .container")
			.prepend(
				() =>
					`<a href="/shop" class="text text--small-ls text--arrow">
					<img src="/wp-content/themes/furniturein/img/arrow.svg" alt="" class="revert">
					<p>Вернуться к каталогу</p>
				</a>`
			)
			.prepend(
				() =>
					`<div class="text text--bold text--upper text--big">
					<p>СРАВНЕНИЕ ТОВАРОВ</p>
				</div>`
			);

		$(".products .button").wrap('<div class="product-btn"/>');

		$(".remove-compare-product").each(function () {
			$(this).closest(".product").find(".product-btn").prepend($(this));
		});

		$(".product-link").addClass("js-bg");
	}

	if ($(".js-products").length !== 0) {
		// $('.js-products .product').each(function () {
		// 	$(this).append('<div class="product-btn"/>');
		//
		// 	const actionWrap = $(this).find('.product-btn');
		// 	const btn = $(this).find('.button');
		// 	const wishlist = $(this).find('.yith-wcwl-add-to-wishlist');
		// 	const compare = $(this).find('.woocommerce-products-compare-compare-button');
		//
		// 	actionWrap.append(compare);
		// 	actionWrap.append(wishlist);
		// 	actionWrap.append(btn);
		// });

		$(".woocommerce-products-compare-compare-button label").on(
			"click",
			function () {
				$(this).addClass("addToCompare");
			}
		);
	}

	// Favodite
	if ($("#yith-wcwl-form").length !== 0) {
		$("#yith-wcwl-form")
			.wrap('<section class="favorites-section def-padding"/>')
			.wrap('<div class="container"/>');

		$(".favorites-section .container").prepend(
			() =>
				`
				<div class="favorites-section__left">
					<div class="text text--bold text--upper text--big text--noWrap">
						<p>ИЗБРАННЫЕ ТОВАРЫ</p>
					</div>
					<a href="/shop" class="text text--small-ls text--arrow">
						<img src="/wp-content/themes/furniturein/img/arrow.svg" alt="" class="revert">
						<p>Вернуться к каталогу</p>
					</a>
				</div>
			`
		);

		$(".favorites-section .remove_from_wishlist").on("click", function () {
			const watch = setInterval(() => {
				if ($(".wishlist-empty").length !== 0) {
					$("#yith-wcwl-form table").remove();
					$(".favorites-section__left").append(
						() =>
							`
						<div class="text text--small-ls text--mt-20">
							<p>
								Нет продуктов добавленных в избранное.
							</p>
						</div>
						`
					);
					clearInterval(watch);
				}
			}, 50);
		});

		if ($(".wishlist-empty").length !== 0) {
			$("#yith-wcwl-form table").remove();
			$(".favorites-section__left").append(
				() =>
					`
						<div class="text text--small-ls text--mt-20">
							<p>
								Нет продуктов добавленных в избранное.
							</p>
						</div>
						`
			);
		}
	}

	// .shop--product
	if ($(".shop--product").length !== 0) {
		$(".custom-select-var")
			.closest(".variations__card")
			.addClass("select-custom-wrap");

		$(".shop--product .summary").prepend(`
			<div class="summary__icons">
				<img class="summary__icons-print" src="${window.location.origin}/wp-content/themes/furniturein/img/print.png" alt="">
				<div class="share">
					<img class="share__img" src="${window.location.origin}/wp-content/themes/furniturein/img/share-product.svg" alt="">
					<div class="share__tooltip">
						<a href="https://vk.com/share.php?url=${window.location.href}" target="_blank">
							<img src="${window.location.origin}/wp-content/themes/furniturein/img/vk.svg" alt="">
						</a>
						<a href="https://www.facebook.com/sharer/sharer.php?u=${window.location.href}" target="_blank">
							<img src="${window.location.origin}/wp-content/themes/furniturein/img/facebook.svg" alt="">
						</a>
						<a href="https://twitter.com/intent/tweet?text=${window.location.href}" target="_blank">
							<img src="${window.location.origin}/wp-content/themes/furniturein/img/twitter.svg" alt="">
						</a>
					</div>
				</div>
			</div>`);

		$(".summary__icons").prepend(
			$(".shop--product .summary .yith-wcwl-add-to-wishlist")
		);
		$(".summary__icons").prepend(
			$(".shop--product .summary .woocommerce-products-compare-compare-button")
		);
	}

	$.fn.extend({
		print: function () {
			var frameName = "printIframe";
			var doc = window.frames[frameName];
			if (!doc) {
				$("<iframe>").hide().attr("name", frameName).appendTo(document.body);
				doc = window.frames[frameName];
			}
			doc.document.body.innerHTML = this.html();
			doc.window.print();
			return this;
		},
	});

	// setTimeout(() => $('#product-1036').print(), 6000);

	$("#product-search-field-1").attr("placeholder", "Я ищу...");

	// Variable card
	function resizeVariations() {
		const wWrap = $(".variations").outerWidth();

		$(".variations__color").each(function () {
			if (wWrap === $(this).outerWidth() - 6) {
				$(this).addClass("more-elem");
			} else {
				$(this).removeClass("more-elem");
			}
		});
	}

	const variablecard = () => {
		$(".custom-select-var").each(function () {
			const id = $(this).attr("id");

			$(this).after(
				() => `<div class="variations__color" data-id="${id}"></div>`
			);
		});

		$(".custom-select-var option").each(function () {
			const img = $(this).attr("data-attr-img");

			$(this)
				.closest(".select-custom-wrap")
				.find(".variations__color")
				.append(
					`<div class="variations__colorCard ${img ? "" : "hid"}"><img src="${
						img ? img : ""
					}" alt=""/></div>`
				);
		});
	};

	$(window).on("resize", () => {
		resizeVariations();
	});

	variablecard();
	resizeVariations();

	$("body").on("click", ".variations__color", function () {
		const select = $(this).closest(".select-custom-wrap").find("select");
		const options = select.find("option");
		const indexActiveColor = $(this)
			.find(".variations__colorCard.active")
			.index();

		//  Clear
		$(".variations__contentPop").html("");
		$(".select-custom-wrap").removeClass("active");
		$(this).closest(".select-custom-wrap").addClass("active");

		options.each(function (i) {
			const img = $(this).attr("data-attr-img");
			const attrValue = $(this).attr("value");
			const text = $(this).text();
			let active;

			if (indexActiveColor !== -1 && indexActiveColor === i) active = true;

			$(".variations__contentPop").append(
				() =>
					`
					<div class="variations__colorPopCard ${active ? "active" : ""} ${
						img ? "" : "hid"
					}" data-value="${attrValue}">
						<div class="variations__colorPopCardImg">
							<img src="${img ? img : ""}" alt=""/>
						</div>
						<span class="text text--upper text--small text--mt-10 text--center text--small-ls">${text}</span>
						<div class="variations__colorPopCardDrop">
							<div class="variations__colorPopCardDropExit">
								<img src="${
									window.location.origin
								}/wp-content/themes/furniturein/img/close-w.svg" alt="">
							</div>
							<img src="${img ? img : ""}" alt=""/>
						</div>
					</div>
				`
			);
		});

		$(".popup-variations").addClass("js-popup-show");
		$("body").addClass("no-scrolling");
	});

	$("body").on(
		"click",
		".variations__colorPopCardImg, .variations__colorPopCard span",
		function () {
			$(".variations__colorPopCard").removeClass("active drop");

			setTimeout(() => {
				let l = [];
				$(".variations__colorPopCard").each(function () {
					l.push($(this).outerHeight());
				});

				const h = Math.max(...l) + 10;

				$(this)
					.closest(".variations__colorPopCard")
					.find(".variations__colorPopCardDrop")
					.css("margin-top", h + "px");
				$(this).closest(".variations__colorPopCard").addClass("active drop");

				const top = document.getElementsByClassName("drop")[0].offsetTop - 10;
				console.log(document.getElementsByClassName("drop"));
				console.log($(".drop"));
				$(".variations__contentPop").animate({ scrollTop: top }, 500);
			}, 300);
		}
	);

	$("body").on("click", ".variations__colorPopCardDropExit", function () {
		$(".variations__colorPopCard").removeClass("drop");
	});

	$("#js-select-variations").on("click", () => {
		if ($(".variations__colorPopCard.active").length === 0) return;

		const text = $(".variations__colorPopCard.active").attr("data-value");
		const activeIndex = $(".variations__colorPopCard.active").index();

		$(
			".select-custom-wrap.active .variations__color .variations__colorCard"
		).removeClass("active");

		$(".select-custom-wrap.active select").val(text);
		$(".select-custom-wrap.active .variations__color .variations__colorCard")
			.eq(activeIndex)
			.addClass("active");
	});

	// Ajax select from product page
	// $('#pa_materialy, #pa_material-obivki, #pa_material-karkasa, #pa_material-stoleshnitsy').map(() => {
	// 	if () {
	//
	// 	}
	// 	$(this).find('option').eq(0).attr('selected', true);
	// });

	$(
		"#pa_materialy, #pa_material-obivki, #pa_material-karkasa, #pa_material-stoleshnitsy"
	).selectric("refresh");

	// Variable card
	const variablecardAsync = (select, data) => {
		if (data === 0) return;

		select.find("option").eq(0).attr("disabled", "disabled");
		select.selectric("refresh");

		const wrap = select
			.closest(".variations__card")
			.next(".select-custom-wrap");
		const wrapSelect = wrap.find("select");

		wrapSelect.html("");
		wrap.find(".variations__colorCard").remove();

		data.forEach(({ image, slug, name }) => {
			wrapSelect.append(
				`<option data-attr-img="${image || ""}" value="${
					slug || ""
				}" class="attached enabled">${name || "Выбрать опцию"}</option>`
			);
		});

		select
			.closest(".variations__card")
			.next(".select-custom-wrap")
			.find("select")
			.find("option")
			.each(function () {
				const img = $(this).attr("data-attr-img");

				$(this)
					.closest(".select-custom-wrap")
					.find(".variations__color")
					.append(
						`<div class="variations__colorCard ${img ? "" : "hid"}"><img src="${
							img ? img : ""
						}" alt=""/></div>`
					);
			});

		wrap.find(".variations__colorCard").eq(0).addClass("active");
		resizeVariations();
	};

	$(
		"#pa_materialy, #pa_material-obivki, #pa_material-karkasa, #pa_material-stoleshnitsy"
	).on("change", function () {
		$(".preloader").removeClass("remove");

		const data = {
			action: "get_pa_materialy",
			factory_type: $("#factory-type").val(),
			attr_type: $(this).attr("id"),
			attr_val: $(this).val(),
		};

		jQuery.post(myajax.url, data, (response) => {
			variablecardAsync($(this), jQuery.parseJSON(response));

			$(".preloader").addClass("remove");
		});
	});

	$(
		"#pa_materialy, #pa_material-obivki, #pa_material-karkasa, #pa_material-stoleshnitsy"
	).trigger("change");

	// Compare
	$(".woocommerce-products-compare-checkbox:checked")
		.closest(".woocommerce-products-compare-compare-button")
		.find("label")
		.addClass("addToCompare");

	// --------------------------------------------------------------------------------------------
	/*global wc_add_to_cart_variation_params */
	(function ($, window, document, undefined) {
		/**
		 * VariationForm class which handles variation forms and attributes.
		 */
		var VariationForm = function ($form) {
			var self = this;

			self.$form = $form;
			self.$attributeFields = $form.find(".variations select");
			self.$singleVariation = $form.find(".single_variation");
			self.$singleVariationWrap = $form.find(".single_variation_wrap");
			self.$resetVariations = $form.find(".reset_variations");
			self.$product = $form.closest(".product");
			self.variationData = $form.data("product_variations");
			self.useAjax = false === self.variationData;
			self.xhr = false;
			self.loading = true;

			// Initial state.
			self.$singleVariationWrap.show();
			self.$form.off(".wc-variation-form");

			// Methods.
			self.getChosenAttributes = self.getChosenAttributes.bind(self);
			self.findMatchingVariations = self.findMatchingVariations.bind(self);
			self.isMatch = self.isMatch.bind(self);
			self.toggleResetLink = self.toggleResetLink.bind(self);

			// Events.
			$form.on(
				"click.wc-variation-form",
				".reset_variations",
				{ variationForm: self },
				self.onReset
			);
			$form.on(
				"reload_product_variations",
				{ variationForm: self },
				self.onReload
			);
			$form.on("hide_variation", { variationForm: self }, self.onHide);
			$form.on("show_variation", { variationForm: self }, self.onShow);
			$form.on(
				"click",
				".single_add_to_cart_button",
				{ variationForm: self },
				self.onAddToCart
			);
			$form.on(
				"reset_data",
				{ variationForm: self },
				self.onResetDisplayedVariation
			);
			$form.on("reset_image", { variationForm: self }, self.onResetImage);
			$form.on(
				"change.wc-variation-form",
				".variations select",
				{ variationForm: self },
				self.onChange
			);
			$form.on(
				"found_variation.wc-variation-form",
				{ variationForm: self },
				self.onFoundVariation
			);
			$form.on(
				"check_variations.wc-variation-form",
				{ variationForm: self },
				self.onFindVariation
			);
			// $form.on( 'update_variation_values.wc-variation-form', { variationForm: self }, self.onUpdateAttributes );

			// Init after gallery.
			setTimeout(function () {
				$form.trigger("check_variations");
				$form.trigger("wc_variation_form");
				self.loading = false;
			}, 100);
		};

		/**
		 * Reset all fields.
		 */
		VariationForm.prototype.onReset = function (event) {
			event.preventDefault();
			event.data.variationForm.$attributeFields.val("").change();
			event.data.variationForm.$form.trigger("reset_data");
		};

		/**
		 * Reload variation data from the DOM.
		 */
		VariationForm.prototype.onReload = function (event) {
			var form = event.data.variationForm;
			form.variationData = form.$form.data("product_variations");
			form.useAjax = false === form.variationData;
			form.$form.trigger("check_variations");
		};

		/**
		 * When a variation is hidden.
		 */
		VariationForm.prototype.onHide = function (event) {
			event.preventDefault();
			event.data.variationForm.$form
				.find(".single_add_to_cart_button")
				.removeClass("wc-variation-is-unavailable")
				.addClass("disabled wc-variation-selection-needed");
			event.data.variationForm.$form
				.find(".woocommerce-variation-add-to-cart")
				.removeClass("woocommerce-variation-add-to-cart-enabled")
				.addClass("woocommerce-variation-add-to-cart-disabled");
		};

		/**
		 * When a variation is shown.
		 */
		VariationForm.prototype.onShow = function (event, variation, purchasable) {
			event.preventDefault();
			if (purchasable) {
				event.data.variationForm.$form
					.find(".single_add_to_cart_button")
					.removeClass(
						"disabled wc-variation-selection-needed wc-variation-is-unavailable"
					);
				event.data.variationForm.$form
					.find(".woocommerce-variation-add-to-cart")
					.removeClass("woocommerce-variation-add-to-cart-disabled")
					.addClass("woocommerce-variation-add-to-cart-enabled");
			} else {
				event.data.variationForm.$form
					.find(".single_add_to_cart_button")
					.removeClass("wc-variation-selection-needed")
					.addClass("disabled wc-variation-is-unavailable");
				event.data.variationForm.$form
					.find(".woocommerce-variation-add-to-cart")
					.removeClass("woocommerce-variation-add-to-cart-enabled")
					.addClass("woocommerce-variation-add-to-cart-disabled");
			}
		};

		/**
		 * When the cart button is pressed.
		 */
		VariationForm.prototype.onAddToCart = function (event) {
			if ($(this).is(".disabled")) {
				event.preventDefault();

				if ($(this).is(".wc-variation-is-unavailable")) {
					window.alert(wc_add_to_cart_variation_params.i18n_unavailable_text);
				} else if ($(this).is(".wc-variation-selection-needed")) {
					window.alert(
						wc_add_to_cart_variation_params.i18n_make_a_selection_text
					);
				}
			}
		};

		/**
		 * When displayed variation data is reset.
		 */
		VariationForm.prototype.onResetDisplayedVariation = function (event) {
			var form = event.data.variationForm;
			form.$product.find(".product_meta").find(".sku").wc_reset_content();
			form.$product.find(".product_weight").wc_reset_content();
			form.$product.find(".product_dimensions").wc_reset_content();
			form.$form.trigger("reset_image");
			form.$singleVariation.slideUp(200).trigger("hide_variation");
		};

		/**
		 * When the product image is reset.
		 */
		VariationForm.prototype.onResetImage = function (event) {
			event.data.variationForm.$form.wc_variations_image_update(false);
		};

		/**
		 * Looks for matching variations for current selected attributes.
		 */
		VariationForm.prototype.onFindVariation = function (event) {
			var form = event.data.variationForm,
				attributes = form.getChosenAttributes(),
				currentAttributes = attributes.data;

			if (attributes.count === attributes.chosenCount) {
				if (form.useAjax) {
					if (form.xhr) {
						form.xhr.abort();
					}
					form.$form.block({
						message: null,
						overlayCSS: { background: "#fff", opacity: 0.6 },
					});
					currentAttributes.product_id = parseInt(
						form.$form.data("product_id"),
						10
					);
					currentAttributes.custom_data = form.$form.data("custom_data");
					form.xhr = $.ajax({
						url: wc_add_to_cart_variation_params.wc_ajax_url
							.toString()
							.replace("%%endpoint%%", "get_variation"),
						type: "POST",
						data: currentAttributes,
						success: function (variation) {
							if (variation) {
								form.$form.trigger("found_variation", [variation]);
							} else {
								form.$form.trigger("reset_data");
								attributes.chosenCount = 0;

								if (!form.loading) {
									form.$form
										.find(".single_variation")
										.after(
											'<p class="wc-no-matching-variations woocommerce-info">' +
												wc_add_to_cart_variation_params.i18n_no_matching_variations_text +
												"</p>"
										);
									form.$form.find(".wc-no-matching-variations").slideDown(200);
								}
							}
						},
						complete: function () {
							form.$form.unblock();
						},
					});
				} else {
					form.$form.trigger("update_variation_values");

					var matching_variations = form.findMatchingVariations(
							form.variationData,
							currentAttributes
						),
						variation = matching_variations.shift();

					if (variation) {
						form.$form.trigger("found_variation", [variation]);
					} else {
						form.$form.trigger("reset_data");
						attributes.chosenCount = 0;

						if (!form.loading) {
							form.$form
								.find(".single_variation")
								.after(
									'<p class="wc-no-matching-variations woocommerce-info">' +
										wc_add_to_cart_variation_params.i18n_no_matching_variations_text +
										"</p>"
								);
							form.$form.find(".wc-no-matching-variations").slideDown(200);
						}
					}
				}
			} else {
				form.$form.trigger("update_variation_values");
				form.$form.trigger("reset_data");
			}

			// Show reset link.
			form.toggleResetLink(attributes.chosenCount > 0);
		};

		/**
		 * Triggered when a variation has been found which matches all attributes.
		 */
		VariationForm.prototype.onFoundVariation = function (event, variation) {
			var form = event.data.variationForm,
				$sku = form.$product.find(".product_meta").find(".sku"),
				$weight = form.$product.find(".product_weight"),
				$dimensions = form.$product.find(".product_dimensions"),
				$qty = form.$singleVariationWrap.find(".quantity"),
				purchasable = true,
				variation_id = "",
				template = false,
				$template_html = "";

			if (variation.sku) {
				$sku.wc_set_content(variation.sku);
			} else {
				$sku.wc_reset_content();
			}

			if (variation.weight) {
				$weight.wc_set_content(variation.weight_html);
			} else {
				$weight.wc_reset_content();
			}

			if (variation.dimensions) {
				// Decode HTML entities.
				$dimensions.wc_set_content(
					$.parseHTML(variation.dimensions_html)[0].data
				);
			} else {
				$dimensions.wc_reset_content();
			}

			form.$form.wc_variations_image_update(variation);

			if (!variation.variation_is_visible) {
				template = wp_template("unavailable-variation-template");
			} else {
				template = wp_template("variation-template");
				variation_id = variation.variation_id;
			}

			$template_html = template({
				variation: variation,
			});
			$template_html = $template_html.replace("/*<![CDATA[*/", "");
			$template_html = $template_html.replace("/*]]>*/", "");

			form.$singleVariation.html($template_html);
			form.$form
				.find('input[name="variation_id"], input.variation_id')
				.val(variation.variation_id)
				.change();

			// Hide or show qty input
			if (variation.is_sold_individually === "yes") {
				$qty.find("input.qty").val("1").attr("min", "1").attr("max", "");
				$qty.hide();
			} else {
				$qty
					.find("input.qty")
					.attr("min", variation.min_qty)
					.attr("max", variation.max_qty);
				$qty.show();
			}

			// Enable or disable the add to cart button
			if (
				!variation.is_purchasable ||
				!variation.is_in_stock ||
				!variation.variation_is_visible
			) {
				purchasable = false;
			}

			// Reveal
			if ($.trim(form.$singleVariation.text())) {
				form.$singleVariation
					.slideDown(200)
					.trigger("show_variation", [variation, purchasable]);
			} else {
				form.$singleVariation
					.show()
					.trigger("show_variation", [variation, purchasable]);
			}
		};

		/**
		 * Triggered when an attribute field changes.
		 */
		VariationForm.prototype.onChange = function (event) {
			var form = event.data.variationForm;

			form.$form
				.find('input[name="variation_id"], input.variation_id')
				.val("")
				.change();
			form.$form.find(".wc-no-matching-variations").remove();

			if (form.useAjax) {
				form.$form.trigger("check_variations");
			} else {
				form.$form.trigger("woocommerce_variation_select_change");
				form.$form.trigger("check_variations");
				$(this).blur();
			}

			// Custom event for when variation selection has been changed
			form.$form.trigger("woocommerce_variation_has_changed");
		};

		/**
		 * Escape quotes in a string.
		 * @param {string} string
		 * @return {string}
		 */
		VariationForm.prototype.addSlashes = function (string) {
			string = string.replace(/'/g, "\\'");
			string = string.replace(/"/g, '\\"');
			return string;
		};

		/**
		 * Updates attributes in the DOM to show valid values.
		 */
		VariationForm.prototype.onUpdateAttributes = function (event) {
			var form = event.data.variationForm,
				attributes = form.getChosenAttributes(),
				currentAttributes = attributes.data;

			if (form.useAjax) {
				return;
			}

			// Loop through selects and disable/enable options based on selections.
			form.$attributeFields.each(function (index, el) {
				var current_attr_select = $(el),
					current_attr_name =
						current_attr_select.data("attribute_name") ||
						current_attr_select.attr("name"),
					show_option_none = $(el).data("show_option_none"),
					option_gt_filter = ":gt(0)",
					attached_options_count = 0,
					new_attr_select = $("<select/>"),
					selected_attr_val = current_attr_select.val() || "",
					selected_attr_val_valid = true;

				// Reference options set at first.
				if (!current_attr_select.data("attribute_html")) {
					var refSelect = current_attr_select.clone();

					refSelect
						.find("option")
						.removeAttr("disabled attached")
						.removeAttr("selected");

					current_attr_select.data(
						"attribute_options",
						refSelect.find("option" + option_gt_filter).get()
					); // Legacy data attribute.
					current_attr_select.data("attribute_html", refSelect.html());
				}

				new_attr_select.html(current_attr_select.data("attribute_html"));

				// The attribute of this select field should not be taken into account when calculating its matching variations:
				// The constraints of this attribute are shaped by the values of the other attributes.
				var checkAttributes = $.extend(true, {}, currentAttributes);

				checkAttributes[current_attr_name] = "";

				var variations = form.findMatchingVariations(
					form.variationData,
					checkAttributes
				);

				// Loop through variations.
				for (var num in variations) {
					if (typeof variations[num] !== "undefined") {
						var variationAttributes = variations[num].attributes;

						for (var attr_name in variationAttributes) {
							if (variationAttributes.hasOwnProperty(attr_name)) {
								var attr_val = variationAttributes[attr_name],
									variation_active = "";

								if (attr_name === current_attr_name) {
									if (variations[num].variation_is_active) {
										variation_active = "enabled";
									}

									if (attr_val) {
										// Decode entities and add slashes.
										attr_val = $("<div/>").html(attr_val).text();

										// Attach.
										new_attr_select
											.find('option[value="' + form.addSlashes(attr_val) + '"]')
											.addClass("attached " + variation_active);
									} else {
										// Attach all apart from placeholder.
										new_attr_select
											.find("option:gt(0)")
											.addClass("attached " + variation_active);
									}
								}
							}
						}
					}
				}

				// Count available options.
				attached_options_count = new_attr_select.find("option.attached").length;

				// Check if current selection is in attached options.
				if (
					selected_attr_val &&
					(attached_options_count === 0 ||
						new_attr_select.find(
							'option.attached.enabled[value="' +
								form.addSlashes(selected_attr_val) +
								'"]'
						).length === 0)
				) {
					selected_attr_val_valid = false;
				}

				// Detach the placeholder if:
				// - Valid options exist.
				// - The current selection is non-empty.
				// - The current selection is valid.
				// - Placeholders are not set to be permanently visible.
				if (
					attached_options_count > 0 &&
					selected_attr_val &&
					selected_attr_val_valid &&
					"no" === show_option_none
				) {
					new_attr_select.find("option:first").remove();
					option_gt_filter = "";
				}

				// Detach unattached.
				new_attr_select
					.find("option" + option_gt_filter + ":not(.attached)")
					.remove();

				// Finally, copy to DOM and set value.
				current_attr_select.html(new_attr_select.html());
				current_attr_select
					.find("option" + option_gt_filter + ":not(.enabled)")
					.prop("disabled", true);

				// Choose selected value.
				if (selected_attr_val) {
					// If the previously selected value is no longer available, fall back to the placeholder (it's going to be there).
					if (selected_attr_val_valid) {
						current_attr_select.val(selected_attr_val);
					} else {
						current_attr_select.val("").change();
					}
				} else {
					current_attr_select.val(""); // No change event to prevent infinite loop.
				}
			});

			// Custom event for when variations have been updated.
			form.$form.trigger("woocommerce_update_variation_values");
		};

		/**
		 * Get chosen attributes from form.
		 * @return array
		 */
		VariationForm.prototype.getChosenAttributes = function () {
			var data = {};
			var count = 0;
			var chosen = 0;

			this.$attributeFields.each(function () {
				var attribute_name =
					$(this).data("attribute_name") || $(this).attr("name");
				var value = $(this).val() || "";

				if (value.length > 0) {
					chosen++;
				}

				count++;
				data[attribute_name] = value;
			});

			return {
				count: count,
				chosenCount: chosen,
				data: data,
			};
		};

		/**
		 * Find matching variations for attributes.
		 */
		VariationForm.prototype.findMatchingVariations = function (
			variations,
			attributes
		) {
			var matching = [];
			for (var i = 0; i < variations.length; i++) {
				var variation = variations[i];

				if (this.isMatch(variation.attributes, attributes)) {
					matching.push(variation);
				}
			}
			return matching;
		};

		/**
		 * See if attributes match.
		 * @return {Boolean}
		 */
		VariationForm.prototype.isMatch = function (
			variation_attributes,
			attributes
		) {
			var match = true;
			for (var attr_name in variation_attributes) {
				if (variation_attributes.hasOwnProperty(attr_name)) {
					var val1 = variation_attributes[attr_name];
					var val2 = attributes[attr_name];
					if (
						val1 !== undefined &&
						val2 !== undefined &&
						val1.length !== 0 &&
						val2.length !== 0 &&
						val1 !== val2
					) {
						match = false;
					}
				}
			}
			return match;
		};

		/**
		 * Show or hide the reset link.
		 */
		VariationForm.prototype.toggleResetLink = function (on) {
			if (on) {
				if (this.$resetVariations.css("visibility") === "hidden") {
					this.$resetVariations.css("visibility", "visible").hide().fadeIn();
				}
			} else {
				this.$resetVariations.css("visibility", "hidden");
			}
		};

		/**
		 * Function to call wc_variation_form on jquery selector.
		 */
		$.fn.wc_variation_form = function () {
			new VariationForm(this);
			return this;
		};

		/**
		 * Stores the default text for an element so it can be reset later
		 */
		$.fn.wc_set_content = function (content) {
			if (undefined === this.attr("data-o_content")) {
				this.attr("data-o_content", this.text());
			}
			this.text(content);
		};

		/**
		 * Stores the default text for an element so it can be reset later
		 */
		$.fn.wc_reset_content = function () {
			if (undefined !== this.attr("data-o_content")) {
				this.text(this.attr("data-o_content"));
			}
		};

		/**
		 * Stores a default attribute for an element so it can be reset later
		 */
		$.fn.wc_set_variation_attr = function (attr, value) {
			if (undefined === this.attr("data-o_" + attr)) {
				this.attr("data-o_" + attr, !this.attr(attr) ? "" : this.attr(attr));
			}
			if (false === value) {
				this.removeAttr(attr);
			} else {
				this.attr(attr, value);
			}
		};

		/**
		 * Reset a default attribute for an element so it can be reset later
		 */
		$.fn.wc_reset_variation_attr = function (attr) {
			if (undefined !== this.attr("data-o_" + attr)) {
				this.attr(attr, this.attr("data-o_" + attr));
			}
		};

		/**
		 * Reset the slide position if the variation has a different image than the current one
		 */
		$.fn.wc_maybe_trigger_slide_position_reset = function (variation) {
			var $form = $(this),
				$product = $form.closest(".product"),
				$product_gallery = $product.find(".images"),
				reset_slide_position = false,
				new_image_id =
					variation && variation.image_id ? variation.image_id : "";

			if ($form.attr("current-image") !== new_image_id) {
				reset_slide_position = true;
			}

			$form.attr("current-image", new_image_id);

			if (reset_slide_position) {
				$product_gallery.trigger("woocommerce_gallery_reset_slide_position");
			}
		};

		/**
		 * Sets product images for the chosen variation
		 */
		$.fn.wc_variations_image_update = function (variation) {
			var $form = this,
				$product = $form.closest(".product"),
				$product_gallery = $product.find(".images"),
				$gallery_nav = $product.find(".flex-control-nav"),
				$gallery_img = $gallery_nav.find("li:eq(0) img"),
				$product_img_wrap = $product_gallery
					.find(
						".woocommerce-product-gallery__image, .woocommerce-product-gallery__image--placeholder"
					)
					.eq(0),
				$product_img = $product_img_wrap.find(".wp-post-image"),
				$product_link = $product_img_wrap.find("a").eq(0);

			if (
				variation &&
				variation.image &&
				variation.image.src &&
				variation.image.src.length > 1
			) {
				// See if the gallery has an image with the same original src as the image we want to switch to.
				var galleryHasImage =
					$gallery_nav.find(
						'li img[data-o_src="' + variation.image.gallery_thumbnail_src + '"]'
					).length > 0;

				// If the gallery has the image, reset the images. We'll scroll to the correct one.
				if (galleryHasImage) {
					$form.wc_variations_image_reset();
				}

				// See if gallery has a matching image we can slide to.
				var slideToImage = $gallery_nav.find(
					'li img[src="' + variation.image.gallery_thumbnail_src + '"]'
				);

				if (slideToImage.length > 0) {
					slideToImage.trigger("click");
					$form.attr("current-image", variation.image_id);
					window.setTimeout(function () {
						$(window).trigger("resize");
						$product_gallery.trigger("woocommerce_gallery_init_zoom");
					}, 20);
					return;
				}

				$product_img.wc_set_variation_attr("src", variation.image.src);
				$product_img.wc_set_variation_attr("height", variation.image.src_h);
				$product_img.wc_set_variation_attr("width", variation.image.src_w);
				$product_img.wc_set_variation_attr("srcset", variation.image.srcset);
				$product_img.wc_set_variation_attr("sizes", variation.image.sizes);
				$product_img.wc_set_variation_attr("title", variation.image.title);
				$product_img.wc_set_variation_attr(
					"data-caption",
					variation.image.caption
				);
				$product_img.wc_set_variation_attr("alt", variation.image.alt);
				$product_img.wc_set_variation_attr(
					"data-src",
					variation.image.full_src
				);
				$product_img.wc_set_variation_attr(
					"data-large_image",
					variation.image.full_src
				);
				$product_img.wc_set_variation_attr(
					"data-large_image_width",
					variation.image.full_src_w
				);
				$product_img.wc_set_variation_attr(
					"data-large_image_height",
					variation.image.full_src_h
				);
				$product_img_wrap.wc_set_variation_attr(
					"data-thumb",
					variation.image.src
				);
				$gallery_img.wc_set_variation_attr(
					"src",
					variation.image.gallery_thumbnail_src
				);
				$product_link.wc_set_variation_attr("href", variation.image.full_src);
			} else {
				$form.wc_variations_image_reset();
			}

			window.setTimeout(function () {
				$(window).trigger("resize");
				$form.wc_maybe_trigger_slide_position_reset(variation);
				$product_gallery.trigger("woocommerce_gallery_init_zoom");
			}, 20);
		};

		/**
		 * Reset main image to defaults.
		 */
		$.fn.wc_variations_image_reset = function () {
			var $form = this,
				$product = $form.closest(".product"),
				$product_gallery = $product.find(".images"),
				$gallery_nav = $product.find(".flex-control-nav"),
				$gallery_img = $gallery_nav.find("li:eq(0) img"),
				$product_img_wrap = $product_gallery
					.find(
						".woocommerce-product-gallery__image, .woocommerce-product-gallery__image--placeholder"
					)
					.eq(0),
				$product_img = $product_img_wrap.find(".wp-post-image"),
				$product_link = $product_img_wrap.find("a").eq(0);

			$product_img.wc_reset_variation_attr("src");
			$product_img.wc_reset_variation_attr("width");
			$product_img.wc_reset_variation_attr("height");
			$product_img.wc_reset_variation_attr("srcset");
			$product_img.wc_reset_variation_attr("sizes");
			$product_img.wc_reset_variation_attr("title");
			$product_img.wc_reset_variation_attr("data-caption");
			$product_img.wc_reset_variation_attr("alt");
			$product_img.wc_reset_variation_attr("data-src");
			$product_img.wc_reset_variation_attr("data-large_image");
			$product_img.wc_reset_variation_attr("data-large_image_width");
			$product_img.wc_reset_variation_attr("data-large_image_height");
			$product_img_wrap.wc_reset_variation_attr("data-thumb");
			$gallery_img.wc_reset_variation_attr("src");
			$product_link.wc_reset_variation_attr("href");
		};

		$(function () {
			if (typeof wc_add_to_cart_variation_params !== "undefined") {
				$(".variations_form").each(function () {
					$(this).wc_variation_form();
				});
			}
		});

		/**
		 * Matches inline variation objects to chosen attributes
		 * @deprecated 2.6.9
		 * @type {Object}
		 */
		var wc_variation_form_matcher = {
			find_matching_variations: function (product_variations, settings) {
				var matching = [];
				for (var i = 0; i < product_variations.length; i++) {
					var variation = product_variations[i];

					if (
						wc_variation_form_matcher.variations_match(
							variation.attributes,
							settings
						)
					) {
						matching.push(variation);
					}
				}
				return matching;
			},
			variations_match: function (attrs1, attrs2) {
				var match = true;
				for (var attr_name in attrs1) {
					if (attrs1.hasOwnProperty(attr_name)) {
						var val1 = attrs1[attr_name];
						var val2 = attrs2[attr_name];
						if (
							val1 !== undefined &&
							val2 !== undefined &&
							val1.length !== 0 &&
							val2.length !== 0 &&
							val1 !== val2
						) {
							match = false;
						}
					}
				}
				return match;
			},
		};

		/**
		 * Avoids using wp.template where possible in order to be CSP compliant.
		 * wp.template uses internally eval().
		 * @param {string} templateId
		 * @return {Function}
		 */
		var wp_template = function (templateId) {
			var html = document.getElementById("tmpl-" + templateId).textContent;
			var hard = false;
			// any <# #> interpolate (evaluate).
			hard = hard || /<#\s?data\./.test(html);
			// any data that is NOT data.variation.
			hard = hard || /{{{?\s?data\.(?!variation\.).+}}}?/.test(html);
			// any data access deeper than 1 level e.g.
			// data.variation.object.item
			// data.variation.object['item']
			// data.variation.array[0]
			hard = hard || /{{{?\s?data\.variation\.[\w-]*[^\s}]/.test(html);
			if (hard) {
				return wp.template(templateId);
			}
			return function template(data) {
				var variation = data.variation || {};
				return html.replace(
					/({{{?)\s?data\.variation\.([\w-]*)\s?(}}}?)/g,
					function (_, open, key, close) {
						// Error in the format, ignore.
						if (open.length !== close.length) {
							return "";
						}
						var replacement = variation[key] || "";
						// {{{ }}} => interpolate (unescaped).
						// {{  }}  => interpolate (escaped).
						// https://codex.wordpress.org/Javascript_Reference/wp.template
						if (open.length === 2) {
							return window.escape(replacement);
						}
						return replacement;
					}
				);
			};
		};

		$(".variations_form *, .variations_form").on("click", function () {
			$(".variations_form").trigger("check_variations");
			$(".variations_form").trigger("wc_variation_form");

			setTimeout(function () {
				$(".variations_form").trigger("check_variations");
				$(".variations_form").trigger("wc_variation_form");
			}, 1000);
		});
	})(jQuery, window, document);

	// --------------------------------------------------------------------------------------------

	$(".single-buy-now button").on("click", function () {
		const h = $(window).outerWidth();

		const inter = setInterval(() => {
			if ($(".saph_content").length !== 0) {
				$(".saph_field").each(function () {
					const input =
						$(this).find("input").length !== 0
							? $(this).find("input")
							: $(this).find("textarea");
					const label = $(this).find("label");

					input.attr("placeholder", label.text());
					label.remove();
				});

				if (h <= 650) {
					$("body, html").animate({ scrollTop: 0 }, 500);
				}

				clearInterval(inter);
			}
		}, 50);
	});

	// $('.js-register').on('click', function (e) {
	// 	e.preventDefault();

	// 	$('.authentication__register').slideUp();
	// 	$('.authentication__login').slideDown();
	// });

	// Dropdown filter
	// const dropdownElements = [
	// 	'.product-search-filter-terms'
	// ];
	//
	// dropdownElements.forEach((elem) => {
	// 	$(elem).addClass('dropdown');
	// 	$(elem).find(`${elem}-heading`).addClass('dropdown__toggle');
	// 	// $(elem).find('ul').wrap('<div class="dropdown__attr"/>');
	// });

	// Filter dropdown
	// $('body').on('click', '.dropdown__toggle', function () {
	// 	let isActive = $(this).closest('.dropdown').hasClass('active');
	//
	// 	$('.dropdown').removeClass('active');
	//
	// 	if (isActive) {
	// 		$(this).closest('.dropdowns').removeClass('active');
	// 	} else {
	// 		$(this).closest('.dropdown').addClass('active');
	// 	}
	// });
	//
	// $(document).on('click', function (e) {
	// 	let div = $(".product-search-filter-terms");
	// 	if (!div.is(e.target) && div.has(e.target).length === 0) {
	// 		div.removeClass('active');
	// 	}
	// });

	// Single product
	if ($(".shop--product").length != 0) {
		$(".single_add_to_cart_button").addClass("btn btn--custom");
		$(".single-buy-now button").addClass("btn");

		const hrefFile = $("#3d_href").val();

		if (hrefFile) {
			$(".saphali-buy-now").append(
				`<a href="${hrefFile}" id="" class="btn btn--def">СКАЧАТЬ 3D</a>`
			);
		} else {
			$(".single-buy-now").addClass("w-100");
		}
		$(".summary .price").after($(".product-advantages"));

		$(".summary").after($(".product-gallery"));

		if ($(".variations_form").length == 0) {
			if (hrefFile) {
				$(".single-buy-now").append(
					`<a href="${hrefFile}" id="" class="btn btn--def">СКАЧАТЬ 3D</a>`
				);
			} else {
				$(".single-buy-now").addClass("w-100");
			}
		}
	}

	$(".summary .onsale").wrap('<div class="oncale-wrap" />');

	if (!$(".product-gallery-slider .swiper-slide").length) {
		$(".product-gallery").remove();
	}

	var productGallerySwiper = new Swiper(".product-gallery-slider", {
		slidesPerView: "auto",
		// initialSlide: 1,
		spaceBetween: 15,
		navigation: {
			nextEl: ".product-gallery__arr--next",
			prevEl: ".product-gallery__arr--prev",
		},
		breakpoints: {
			599: {
				slidesPerView: 1,
				autoHeight: true,
			},
		},
	});

	var productConfigSwiper = new Swiper(".product-config__slider", {
		slidesPerView: 9,
		spaceBetween: 10,
		centeredSlides: true,
		initialSlide: Math.floor($(".product-config__slider a").length / 2) || 0,
		navigation: {
			nextEl: ".product-config__arr--next",
			prevEl: ".product-config__arr--prev",
		},
		breakpoints: {
			1366: {
				slidesPerView: 6,
			},
			599: {
				slidesPerView: 4,
			},
		},
	});

	if (!$(".product-config__slider .swiper-slide").length) {
		$(".product-config").css("opacity", 0);
	}

	$(window).on("load resize", function () {
		const w = $(this).outerWidth();
		const arr = $(".product-config__arr");

		if (w > 1366) {
			if ($(".product-config__slider .swiper-slide").length <= 9) {
				arr.addClass("hide");
			} else {
				arr.removeClass("hide");
			}
		}

		if (w <= 1366 && w > 599) {
			if ($(".product-config__slider .swiper-slide").length <= 6) {
				arr.addClass("hide");
			} else {
				arr.removeClass("hide");
			}
		}

		if (w <= 599) {
			if ($(".product-config__slider .swiper-slide").length <= 4) {
				arr.addClass("hide");
			} else {
				arr.removeClass("hide");
			}
		}
	});

	$(".woocommerce-product-gallery").append($(".product-config"));

	// Reviews
	$(".js-text-review").html(function (index, currentText) {
		const maxLenText = 1200;
		const parent = $(this).closest(".js-parent-review");

		if ($(this).text().length <= maxLenText) {
			parent.find("a").remove();
			return currentText;
		} else {
			const newText = currentText.slice(maxLenText);
			parent.find(".js-input-review").text(newText);
			return currentText.substr(0, maxLenText) + "...";
		}
	});

	$(".js-parent-review a").on("click", function (e) {
		e.preventDefault();

		const parent = $(this).closest(".js-parent-review");

		parent
			.find(".js-text-review")
			.text(parent.find(".js-text-review").text().slice(0, -3));
		parent.find(".js-input-review").slideDown(300);
		parent.find("a").remove();
	});

	var productBigSwiper = new Swiper(".popup__product-swiper", {
		slidesPerView: 1,
		spaceBetween: 0,
		keyboard: {
			enabled: true,
		},
		autoHeight: true,
		navigation: {
			nextEl: ".popup__product-controlCard.next",
			prevEl: ".popup__product-controlCard.prev",
		},
	});

	$(".product-gallery-slider .swiper-slide").on("click", function () {
		var indexHead = $(this).index();
		productBigSwiper.slideTo(indexHead, 0);
		$(".popup.swiperBig").addClass("js-popup-show");
	});

	$(".summary__icons-print").on("click", function () {
		window.print();
	});

	$(
		"table.shop_attributes p, .woocommerce-products-compare-content tbody p"
	).each((i, e) => {
		const h = $(e).outerHeight();
		if (h > 16) {
			$(e).text("На выбор");
		}
	});

	$("body").on("click", ".product-search-filter-terms", function () {
		$(this).toggleClass("active");
	});

	$(".product-search-filter-terms ul").each(function () {
		if (!$(this).find("li").length)
			$(this).closest(".product-search-filter-terms").remove();
	});

	$(".shop--product .shop_attributes tr").map(function () {
		if (
			$(this).find("th").text() === "Вес" ||
			$(this).find("th").text() === "Габариты"
		) {
			$(this).remove();
		}
	});

	$("#billing_city_field, #billing_state_field").wrapAll(
		'<div class="input-wrap" />'
	);
	$(".woocommerce-form-login-toggle, .woocommerce-form-login.login").wrapAll(
		'<div class="check-login" />'
	);

	$(".checkout-section__main .col-1").append($(".check-login"));

	$(".check-login .button").attr(
		"class",
		"btn btn--def btn--custom btn--custom-w btn--100"
	);

	$(".woocommerce-order > h3, .woocommerce-order > table").wrapAll(
		'<div class="delo-line" />'
	);

	// Ie Image
	navigator.sayswho = (function () {
		let ua = navigator.userAgent,
			M =
				ua.match(
					/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
				) || [];
		if (/trident/i.test(M[1])) {
			return "IE";
		}
		return M.join(" ");
	})();

	if (navigator.sayswho === "IE") {
		$(".js-bg").each(function () {
			const img = $(this).find("img");
			const url = img.attr("src");

			$(this).css("background", `url(${url}) center / cover`);
			img.css("opacity", "0");
		});
	}

	// Loader
	$(".height-helper").css("opacity", 1);
	$(".preloader").addClass("remove");

	// Footer
	clampingFooter();

	// Error page
	if ($(".error-page").length !== 0) {
		$("footer").remove();

		$(".header").addClass("active-error");
	}

	// const video = document.getElementById('video');
	//
	// video.addEventListener('canplay', function(e) {
	// 	this.play();
	// }, false);
});
